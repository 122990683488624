/* eslint-disable max-len */
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import companyLogoActuation from '../../assets/images/company_logos/actuation.png';
import companyLogoCostain from '../../assets/images/company_logos/costain.png';
import companyLogoFathom from '../../assets/images/company_logos/fathom.png';
import companyLogoI2 from '../../assets/images/company_logos/i2.png';
import companyLogoNetzero from '../../assets/images/company_logos/nztc.png';
import companyLogoPipeline from '../../assets/images/company_logos/pipeline.png';
import companyLogoRovop from '../../assets/images/company_logos/rovop.png';
import { API_DASHBOARD_CONTRACTOR } from '../../consts/urls';
import AppNavbar from './AppNavbar';
import Footer from './Footer';
import style from './style.module.scss';
import NudgeButton from '../../components/buttons/NudgeButton';

const ContactFormSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'First name is too short.')
        .max(80, 'First name is too long.')
        .required('First name is required.'),
    last_name: Yup.string()
        .min(2, 'Last name is too short.')
        .max(80, 'Last name is too long.')
        .required('Last name is required.'),
    email: Yup.string().email('Invalid email').required('Email is required.'),
    subject: Yup.string()
        .min(2, 'Subject is too short.')
        .max(255, 'Subject is too long.')
        .required('Subject is required.'),
    message: Yup.string()
        .min(2, 'Message is too short.')
        .max(4000, 'Message is too long.')
        .required('Message is required.'),
    terms: Yup.boolean().oneOf([true], 'You must agree to the terms.'),
});

const CompanyPage = () => {
    const [token, setToken] = useState();
    const [formComplete, setFormComplete] = useState(false);

    const scrollContactForm = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (document.location.href.includes('#contact')) {
            scrollContactForm();
        }
    }, []);

    const handleVerify = useCallback((token) => {
        setToken(token);
    }, []);

    const submitContactForm = (values) => {
        var data = values;
        data['gRcaptchaResponse'] = token;

        return new Promise((resolve) => {
            fetch(`${API_DASHBOARD_CONTRACTOR}/HomepageContact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then((res) => {
                if (res.status === 200) {
                    setFormComplete(true);
                }
                resolve(true);
            });
        });
    };

    return (
        <div>
            <AppNavbar />
            <Helmet>
                <title>Companies | Nudge Talent Lab</title>
            </Helmet>

            <section className={style.sectionBest}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={style.firstSection}>
                                <h1>The best candidates at the touch of a button</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionSaveTime}>
                <Container>
                    <Row>
                        <Col xs={12} xl={6}>
                            <div className={style.subHeading}>Save time while giving meaningful feedback</div>
                        </Col>
                        <Col xs={12} xl={6}>
                            <div className={style.subText} style={{ marginTop: '40px' }}>
                                Sifting through job applications, scheduling meetings and screening candidates.
                                It&apos;s time consuming.
                            </div>
                            <div className={style.subsubText} style={{ marginTop: '20px' }}>
                                At Nudge, we provide companies with energy sector talent as and when needed, bringing AI
                                and humans together in harmony to efficiently assess candidates for your company or
                                project.
                                <br />
                                <br />
                                You&apos;ll meet with our team to walk you through the process and ensure we&apos;re a
                                good fit.
                            </div>
                            <div style={{ marginTop: '10vh' }}>
                                <Link
                                    to="/services/company#contact"
                                    className={style.buttonChalk}
                                    onClick={scrollContactForm}
                                >
                                    <span>Join our club →</span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionLeaders}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={style.subHeading} style={{ textAlign: 'center', margin: '5vh 0 10vh 0' }}>
                                Industry leaders we work with:
                            </div>
                            <div style={{ textAlign: 'center', marginBottom: '10vh' }}>
                                <Carousel showDots cols={4} loop>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoI2} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoNetzero} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoRovop} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoActuation} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoCostain} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoFathom} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoPipeline} alt="Acuation Lab logo" />
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <div style={{ textAlign: 'center', padding: '0 10vw' }}>
                                <Carousel showDots cols={1} loop>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span>
                                                &nbsp;We found Nudge to be easy to work with and were pleased when we
                                                were presented with a short list of suitable candidates within the
                                                timescale, all of whom were more than capable of performing the role and
                                                provided us with a good cross section of experience levels and day
                                                rates. From this short list, we quickly selected our preferred candidate
                                                and progressed discussions directly with them.
                                                <br />
                                                <br />
                                                We would certainly use Nudge again for future roles and have no
                                                hesitation in recommending their service to other potential
                                                clients.&nbsp;
                                                <br />
                                                <br />
                                                <strong>
                                                    Rob Gallacher MICE CEng
                                                    <br />
                                                    Falck Renewables Wind Ltd
                                                    <br />
                                                    Engineering Manager
                                                </strong>
                                            </span>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span>
                                                &nbsp;We recently used Nudge Exchange to source a contract Laser Scan
                                                Surveyor to support with increased workload for Costain in Aberdeen.
                                                <br />
                                                <br />
                                                We found the system easy to use, any questions were answered quickly and
                                                the quality of contractors high.It was a straightforward, stress free
                                                process all round.&nbsp;
                                                <br />
                                                <br />
                                                <strong>
                                                    Sophie Darley (she/her)
                                                    <br />
                                                    Digital Recruitment Business Partner &amp; Oil &amp; Gas Recruiter
                                                    <br />
                                                    Co-Chair: Women’s Network, Costain
                                                </strong>
                                            </span>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span>
                                                &nbsp;Nudge was quick and easy to use, supplying us with a portfolio of
                                                suitable candidates at the touch of a button.
                                                <br />
                                                <br />
                                                Within no time the vacancy was filled, with the new staff member
                                                slotting into our existing team seamlessly.&nbsp;
                                                <br />
                                                <br />
                                                <strong>
                                                    Anonymous
                                                    <br />
                                                    Drawing Office Manager
                                                </strong>
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionJoin}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div
                                className={style.subHeading}
                                style={{ textAlign: 'center', margin: '5vh 0 10vh 0' }}
                                id="contact"
                            >
                                Join our club
                            </div>

                            {formComplete ? (
                                <h3 style={{ textAlign: 'center', margin: '0 0 10vh 0' }}>
                                    Thank you for your interest in joining Nudge! We will be in touch shortly.
                                </h3>
                            ) : (
                                <>
                                    <h3 style={{ textAlign: 'center', margin: '0 0 10vh 0' }}>
                                        Upload your requirements. We&apos;ll Nudge you with everything you need to make
                                        a decision.
                                    </h3>
                                    <Formik
                                        initialValues={{
                                            first_name: '',
                                            last_name: '',
                                            email: '',
                                            subject: '',
                                            message: '',
                                            terms: false,
                                        }}
                                        validationSchema={ContactFormSchema}
                                        onSubmit={submitContactForm}
                                    >
                                        {({ errors, touched, isSubmitting }) => (
                                            <Form className={style.contactform}>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12} xl={6}>
                                                        <Label className={style.label} for="first_name">
                                                            First name
                                                        </Label>
                                                        <Field
                                                            name="first_name"
                                                            label="First name"
                                                            type="text"
                                                            placeholder="Jane"
                                                            className="form-control"
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="text-danger">{errors.first_name}</div>
                                                        ) : null}
                                                    </Col>
                                                    <Col xs={12} xl={6}>
                                                        <Label className={style.label} for="last_name">
                                                            Last name
                                                        </Label>
                                                        <Field
                                                            name="last_name"
                                                            label="Last name"
                                                            type="text"
                                                            placeholder="Waterson"
                                                            className="form-control"
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="text-danger">{errors.last_name}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <Label className={style.label} for="email">
                                                            Email
                                                        </Label>
                                                        <Field
                                                            name="email"
                                                            label="Email"
                                                            type="email"
                                                            placeholder="janewaterson@email.com"
                                                            className="form-control"
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="text-danger">{errors.email}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <Label className={style.label} for="subject">
                                                            Subject
                                                        </Label>
                                                        <Field
                                                            name="subject"
                                                            label="Subject"
                                                            type="text"
                                                            placeholder="Application Enquiry"
                                                            className="form-control"
                                                        />
                                                        {errors.subject && touched.subject ? (
                                                            <div className="text-danger">{errors.subject}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <Label className={style.label} for="message">
                                                            Your Message
                                                        </Label>
                                                        <Field
                                                            name="message"
                                                            label="Message"
                                                            as="textarea"
                                                            placeholder="Your Message"
                                                            className="form-control"
                                                            rows="5"
                                                        />
                                                        {errors.message && touched.message ? (
                                                            <div className="text-danger">{errors.message}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <Field
                                                            type="checkbox"
                                                            name="terms"
                                                            className={`form-control ${style.agreeterms}`}
                                                        />
                                                        <Label className={style.agreetermslabel} for="terms">
                                                            I agree to the site&apos;s{' '}
                                                            <a
                                                                href="https://nudgedev.nudgeexchange.com/static/media/NudgeTermsAndConditions.pdf"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Terms &amp; Conditions
                                                            </a>
                                                        </Label>
                                                        {errors.terms && touched.terms ? (
                                                            <div className="text-danger">{errors.terms}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <GoogleReCaptcha onVerify={handleVerify} />
                                                        <p>
                                                            This site is protected by reCAPTCHA and the Google{' '}
                                                            <a href="https://policies.google.com/privacy">
                                                                Privacy Policy
                                                            </a>{' '}
                                                            and{' '}
                                                            <a href="https://policies.google.com/terms">
                                                                Terms of Service
                                                            </a>{' '}
                                                            apply.
                                                        </p>
                                                    </Col>
                                                </Row>

                                                <Row className={style.contactrow}>
                                                    <Col xs={12}>
                                                        <div style={{ marginTop: '0vh' }}>
                                                            <NudgeButton
                                                                type="submit"
                                                                color="success"
                                                                id="button-light-success"
                                                            >
                                                                Join our club
                                                            </NudgeButton>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    );
};

export default CompanyPage;
