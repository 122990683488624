import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, authPostData, putData } from '../../../../../../api/tools';

import { ADMIN } from '../../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../../components/buttons/NudgeButton';

const TestingGround = ({ id }) => {
    const [data, setData] = React.useState();
    async function getCases() {
        const getCaseUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/case`;

        const findBody = {
            projectId: id,
        };

        const response = await authPostData(findBody, getCaseUrl);
        setData(response);
    }

    return (
        <>
            {data ? data.map((item, index) => <div>{item.id}</div>) : null}
            <ClickButton onClick={getCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
