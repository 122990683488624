export const OFFER_STATUS = {
    OPEN: 'Open',
    EXPIRED: 'Expired',
    CLOSED: 'Closed',
};

export const QUALIFICATIONS_OPTIONS = [
    { label: 'NQ', value: 'NQ' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'HNC', value: 'HNC' },
    { label: 'HND', value: 'HND' },
    { label: 'BA', value: 'BA'},
    { label: 'BA (Hons)', value: 'BA (Hons)'},
    { label: 'BSc', value: 'BSc' },
    { label: 'MA', value: 'MA'},
    { label: 'MSc', value: 'MSc' },
    { label: 'PhD', value: 'PhD' },
];
