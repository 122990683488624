import React, { useEffect } from 'react';

import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { COMPANY, USER } from '../../../consts/userRoles';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { ProcessSalary, capitaliseFirstLetter } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import { Col, Button } from 'reactstrap';
import SearchBox from '../../../components/SearchBox';
import useSWR from 'swr';

import { exFetcher, NEVER_REFRESH } from '../../../api/tools';
import { Row } from 'reactstrap';

import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';

const APP_ID = 'de0a2d37';
const APP_KEY = '6b89e573b8421debfe1173daae90a3b6';

function getHistogramURL(title) {
    return `http://api.adzuna.com/v1/api/jobs/gb/histogram?app_id=${APP_ID}&app_key=${APP_KEY}&location0=UK&location1=Scotland&what=${title}&content-type=application/json`;
}

function getAverageSalaryURL(title) {
    var seedURL = `https://api.adzuna.com:443/v1/api/jobs/gb/search/1?app_id=${APP_ID}&app_key=${APP_KEY}`;

    const props = [`&results_per_page=${0}`, `&what=${title}`, `&content-type=application/json`];

    props.forEach((item) => {
        seedURL += item;
    });

    return seedURL;
}

export const OfferInfo = ({ data }) => {
    const [histogramHighest, setHistogramHighest] = React.useState(``);
    const [averageSalary, setAverageSalary] = React.useState(``);

    const [jobTitle, setJobTitle] = React.useState(`software developer`);
    const [search, setSearch] = React.useState(jobTitle);

    const { data: averageSalaryData, isValidating } = useSWR(getAverageSalaryURL(jobTitle), exFetcher, NEVER_REFRESH);

    useEffect(() => {
        if (averageSalaryData) {
            var aS = ProcessSalary(averageSalaryData.mean);

            if (averageSalaryData.count == 0) {
                aS = 0;
            }

            setAverageSalary(`${aS}`);
        }
    }, [averageSalaryData]);

    function updateJobTitle(search) {
        if (search && search != jobTitle) {
            setJobTitle(search);
        }
    }

    return (
        <SignInPageTemplate>
            <h5 className={style.header}>Salary Predictor</h5>
            <p className={style.subtitle}>
                Work in progress text. Type a job title into the box and press enter or the search button.
            </p>
            <p className={style.subtitle}>Keep in mind this does not account for other factors such as experience.</p>
            <div className={style.input}>
                <Row>
                    <Col xs={0} md={3}></Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={9} md={9}>
                                <SearchBox
                                    value={search}
                                    onEnter={() => updateJobTitle(search)}
                                    setValue={setSearch}
                                    button={false}
                                    placeholder={'Search by job title...'}
                                />
                            </Col>
                            <Col xs={3} md={3}>
                                <ClickButton
                                    className={style.button}
                                    color="success"
                                    onClick={search && search != jobTitle ? () => setJobTitle(search) : null}
                                >
                                    Search
                                </ClickButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} md={3}></Col>
                </Row>
                <h5 style={{ textAlign: 'center' }}>
                    {averageSalaryData?.count > 0 ? (
                        <h5 className={style.money}>{averageSalary}</h5>
                    ) : !isValidating ? (
                        <h5>No results returned. Try a different search term or try again later.</h5>
                    ) : (
                        <h5 className={style.money}>Loading...</h5>
                    )}
                </h5>
            </div>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(OfferInfo, [COMPANY, USER]);
