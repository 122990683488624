import React from 'react';

import { FIELDS, initialObject } from '../../validation';
import AppInput from '../../../../../../components/AppInput';
import OfferField from '../../OfferField';

import style from '../../style.module.scss';
import { Col, Row } from 'reactstrap';
import { Field } from 'formik';
import SelectField from '../../../../../../components/SelectField';

import { API_DASHBOARD_CONTRACTOR, API_PROFILE } from '../../../../../../consts/urls';
import NewInputForm from '../../../../../../components/forms/NewInputForm';
import { QUALIFICATIONS_OPTIONS } from '../../../../../../consts/offers';
import RequiredBox from '../../RequiredBox';
import { usePlusButtonWithAlwaysMinus } from '../../../../../../components/buttons/PlusButton';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';
import AttachedFormBody from '../../../../../../components/forms/AttachedFormBody';

const File = ({ name, url }) => (
    <div className={style.file}>
        <a target="_blank" rel="noopener noreferrer" href={url}>
            <FontAwesomeIcon icon={['fal', 'file-certificate']} className={style.certificateIcon} />
        </a>
        {name}
    </div>
);

const RemovableFile = ({ name, url, onRemove }) => (
    <div className={style.removableCertificate}>
        <File name={name} url={url} />
        <MinusButton onClick={onRemove} />
    </div>
);

const SkillsEducation = ({ formik, isEdit, tooltip, files }) => {

    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);

    return (<>
        <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Skills"
                    click={() => tooltip.set(FIELDS.SKILLS)}
                >
                    <NewInputForm
                        formik={formik}
                        addValue={initialObject[FIELDS.SKILLS]}
                        selected={tooltip}
                        field={FIELDS.SKILLS}
                        subField={'name'}
                        fieldLimit={12}
                        hasRequired
                        autocompleteUrl={`${API_DASHBOARD_CONTRACTOR}/Skills/All`}
                    />
                </OfferField>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Certifications/Qualifications Requested"
                    click={() => tooltip.set(FIELDS.CERTIFICATES)}
                >
                    <NewInputForm
                        formik={formik}
                        addValue={initialObject[FIELDS.CERTIFICATES]}
                        selected={tooltip.get}
                        field={FIELDS.CERTIFICATES}
                        subField={'name'}
                        fieldLimit={10}
                        hasRequired
                        autocompleteUrl={`${API_PROFILE}/Certificate`}
                    />
                </OfferField>
            </Col>
        </Row>
        <div className={style.divider}/>
        <Row>
            <Col sm={12} md={12} lg={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Minimum Education"
                    click={() => tooltip.set(FIELDS.MIN_QUALIFICATIONS)}
                >
                    <Row>
                        <Col xs={4} sm={3} md={3} lg={3}>
                            <Field
                                name={FIELDS.MIN_QUALIFICATIONS}
                                component={SelectField}
                                options={QUALIFICATIONS_OPTIONS}
                                placeholder="Type"
                            />
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={6}>
                            <AppInput
                                id={FIELDS.STUDY_FIELD}
                                formik={formik}
                                label="Field of Study"
                            />
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                            <RequiredBox formik={formik} basicName={FIELDS.QUAL_REQUIRED} />
                        </Col>
                    </Row>
                </OfferField>
            </Col>
            <Col sm={12} md={12} lg={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Upload Additional Files"
                    click={() => tooltip.set(FIELDS.FILES)}
                >
                    {isEdit
                        ? files.map((el) => (
                                <RemovableFile
                                    url={el.url}
                                    name={el.name}
                                    key={el.name}
                                    onRemove={() => onRemove(el)}
                                />
                            ))
                        : null}
                    
                        {isPlusButtonShowed(FIELDS.FILES) ? (
                            <Row>
                                <Col>
                                    <h5 style={{ fontSize: '1.3rem' }}>Limit: 0/8</h5>
                                </Col>
                                <Col sm={6} style={{ textAlign: 'right' }}>
                                    <NudgeButton style={{ width: '100%' }} onClick={onClickPlusButton(FIELDS.FILES)}>+</NudgeButton>
                                </Col>
                            </Row>
                        ) : null}
                        <AttachedFormBody formik={formik} alwaysShowMinus basicName={FIELDS.FILES} />
                </OfferField>
            </Col>
        </Row>
    </>)
}

export default SkillsEducation;