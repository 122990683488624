import * as Yup from 'yup';

export const FIELDS = {
    FEEDBACK: 'feedback',
    OTHER: 'other',
};

export const validationSchema = Yup.object({
    [FIELDS.OTHER]: Yup.string().required('This field is required'),
});

export const OPTIONS = {
    INTERNAL: 'This position has been filled internally.',
    NOT_AVAILABLE: 'This position is no longer available.',
    OTHER: 'Other',
};

export const initialValues = {
    [FIELDS.FEEDBACK]: OPTIONS.NOT_AVAILABLE,
    [FIELDS.OTHER]: '',
};
