import React from 'react';
import { Button } from 'reactstrap';

import { authPostData } from '../../../../../api/tools';
import { API_DASHBOARD_CLOOD } from '../../../../../consts/urls';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

const TestingGround = ({ id }) => {
    const [alert, setAlert] = React.useState(``);
    const [progress, setProgress] = React.useState('');

    async function retainCases() {
        try {
            const response = await authPostData({}, `${API_DASHBOARD_CLOOD}/Projects/RetainAllContractors`, {});
        } catch (e) {
            console.log('Something went wrong: ' + e.message);
        }
    }

    return (
        <>
            {progress ? <h5>{progress}</h5> : null}
            {alert ? <h5>{alert}</h5> : null}
            <Button onClick={retainCases} color={'success'}>
                Post
            </Button>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
