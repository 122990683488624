import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, deleteWithAuthorizationHeader } from '../../../../../api/tools';

import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../components/buttons/NudgeButton';

const TestingGround = ({ id }) => {
    const [success, setSuccess] = React.useState(false);
    async function clearCases() {
        const deleteUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/${id}/case`;
        const response = await deleteWithAuthorizationHeader(deleteUrl, token);

        if (response) {
            setSuccess(true);
        }
    }

    return (
        <>
            {success ? 'Cases cleared' : null}
            <ClickButton onClick={clearCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
