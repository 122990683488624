import React from 'react';

import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';

const NudgeBoxes = ({ data, rows = 1, fullWidth, halfWidth }) => {
    const theme = GetPageTheme();

    return (
        data.length ? <>
            <div id={`boxes-${theme}`} className={`${style.boxesWrapper} ${fullWidth ? style.margins : null}`} style={{ minHeight: `${36*rows}px`}}>
                { data.map((box, index) => box?.label ? <span key={`box-${index}`} className={box.danger ? style.boxRed : style.box} style={{ width: fullWidth ? '100%' : halfWidth ? "50%" : "", textAlign: fullWidth || halfWidth ? 'center' : ''  }}>
                    { box?.label }
                </span> : null )}
            </div>
        </> : <div id={`boxes-${theme}`} className={`${style.boxesWrapper} ${fullWidth ? style.margins : null}`} style={{ minHeight: `${36*rows}px`}}>
            <span key={`box-empty`} className={style.box} style={{ width: fullWidth ? '100%' : halfWidth ? "50%" : "", textAlign: fullWidth || halfWidth ? 'center' : ''  }}>
                <i>Empty</i>
            </span>
        </div>
    );
};

export default NudgeBoxes;
